import React, { useState } from 'react'
import { graphql } from 'gatsby'

import TopBanner from '../../../components/Banners/TopBanner/TopBanner'
import Header from '../../../components/Header/Header'
import Footer from '../../../components/Footer/Footer'
import SubLeader from '../../../components/SubLeader/SubLeader'
import BlockResults from '../../../components/BlockResults/BlocksResults'
// eslint-disable-next-line node/no-extraneous-import
import { useQuery } from '@apollo/client'
import PageMeta from '../../../components/PageMeta/PageMeta'
import { NationalWeekendEscape } from '../../../types'
import { CATEGORIES } from '../../../lib/data/SchoolReviews'
import SchoolReviewsLauncher from '../../../components/organisms/schoolReviewsLauncher/SchoolReviewsLauncher'
import {
  SchoolReviewsResultQuery,
  type ISchoolReviewsResultQueryData,
  type ISchoolReviewsSearchQueryData
} from '../../../graphql/queries/SchoolReviewsSearchQuery'
import Leaderboard from '../../../components/Banners/Leaderboard/Leaderboard'

export const query = graphql`
  query {
    wpgraphql {
      adStarCodes {
        id
        adslot
      }
      generalSettings {
        title
        siteId
        egSettingTwitter
      }
    }
  }
`

interface SchoolReviewsSearchProps {
  data: ISchoolReviewsSearchQueryData
  category: string
}

const SchoolReviewsSearch = ({
  data: {
    wpgraphql: { adStarCodes, generalSettings }
  },
  category
}: SchoolReviewsSearchProps) => {
  const [searchTerm, setSearchTerm] = useState<string | null>(null)

  const {
    data: escapes,
    loading,
    error
  } = useQuery<ISchoolReviewsResultQueryData>(SchoolReviewsResultQuery, {
    variables: { category }
  })

  const filterSearch = (results: NationalWeekendEscape[]) => {
    if (searchTerm)
      return results.filter(escape =>
        escape.title
          ?.replace(/'/g, '')
          .replace(/&#8217;/g, '')
          .replace(/\./g, '')
          .toLowerCase()
          .includes(
            searchTerm
              .replace(/'/g, '')
              .replace(/&#8217;/g, '')
              .replace(/\./g, '')
              .toLowerCase()
          )
      )

    return results
  }

  const handleSearchChange = (searchTerm: string) => {
    setSearchTerm(searchTerm)
  }

  return (
    <>
      <PageMeta
        pageData={{
          ...generalSettings,
          title: `${
            CATEGORIES[category] ?? 'School Reviews'
          } | Muddy Stilettos' Best Schools Guide`,
          description:
            'No boring fusty reviews here! Our ‘insider’ guides give a genuine insight into each school we visit, with informal, super-useful advice from our editors.',
          image:
            'https://muddystilettos.co.uk/images/school-reviews/Leader.jpg',
          url: `https://muddystilettos.co.uk/school-reviews/search/${category}`
        }}
      />
      <Header
        siteId={generalSettings.siteId}
        siteName={generalSettings.title}
      />
      <TopBanner ads={adStarCodes} pageType={`readertreats`} />
      <Leaderboard adSlot={'TopLB'} sticky />
      <SubLeader>
        <SchoolReviewsLauncher
          handleSearchChange={handleSearchChange}
          showH1
          selectedCategory={category}
        />
      </SubLeader>
      {error ? (
        <>Problem loading School Reviews</>
      ) : (
        <BlockResults
          category={CATEGORIES[category]}
          loading={loading}
          posts={filterSearch(escapes?.nationalSchoolReviews ?? [])}
          type={`school-reviews`}
        />
      )}
      <Leaderboard adSlot={'BottomLB'} />
      <Footer />
    </>
  )
}

export default SchoolReviewsSearch
